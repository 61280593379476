<template>
<div>
  Posts
</div>
</template>

<script>
export default {
  name: "Posts"
}
</script>

<style scoped>

</style>