<template>
<div>
  projects
</div>
</template>

<script>
export default {
  name: "Projects"
}
</script>

<style scoped>

</style>